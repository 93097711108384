jQuery(document).ready(function(){
	jQuery('.sexycourses.closeBtn').on('click', function(){
		jQuery('#next_courses').slideUp();
	});

	jQuery('.club-anmeldung').on('click', function(e){
		jQuery('form.hidden').css('display', 'block');
			jQuery('html, body').animate({
	        scrollTop: jQuery("#club-anmeldung").offset().top - 100
	    }, 450);

		trackEvent('form', 'click', 'show club form');
	});
});