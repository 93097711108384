function processAutoheight() {
    var maxHeight = 0;

    jQuery(".autoheight > *").each(function () {
        height = jQuery(this).outerHeight(true);
        if (height > maxHeight) {
            maxHeight = height;
        }
    });
    if (jQuery('body').hasClass('page-template-template-club')) {
        jQuery(".autoheight").height(maxHeight + 200);
    } else {
        jQuery(".autoheight").height(maxHeight + 100);
    }

}

function travelGallery() {
    jQuery(".gallery-carousel").slick({
        centerMode: true,
        centerPadding: '0px',
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        focusOnSelect: true,
        arrows: true,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1
                }
            }
        ]
    });
}
