function generatePopOverTemplate() {


    var content = function () {
        var organizedContent = [
            jQuery(this).data('schedule'),
            jQuery(this).data('courseleader'),
            jQuery(this).data('selectcourse'),
        ];
        return organizedContent;
    };

    var popoverTemplate = ['<div class="course-details popover">',
        '<h4 class="popover-title"></h4>',
        '<div class="arrow"></div>',
        '<div class="popover-content">',
        '</div>',
        '</div>'
    ].join('');

    jQuery('[data-toggle="popover"]').popover({
        trigger: "manual",
        html: true,
        animation: false,
        template: popoverTemplate,
        content: content,
        placement: 'bottom'
    })
        .on("mouseenter", function () {
            var _this = this;
            jQuery(this).popover("show");
            jQuery(".popover").on("mouseleave", function () {
                jQuery(_this).popover('hide');
            });
        }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!jQuery(".popover:hover").length) {
                jQuery(_this).popover("hide");
            }
        }, 150);
    }).on('click', function () {
        var _this = this;
        jQuery('.courseBlock').removeClass('selected');
        jQuery('form.hidden').css('display', 'none');
        jQuery(this).addClass('selected');

        var courseID = jQuery(this).attr('data-courseID');
        var isReferal = jQuery(this).attr('data-referal');
        jQuery('.dyn-course select').val(courseID);

        if (isReferal === "1") {
            jQuery('.referal-form form.hidden').css('display', 'block');
            jQuery('.non-referal-form form.hidden').css('display', 'none');
        } else {
            jQuery('.non-referal-form form.hidden').css('display', 'block');
            jQuery('.referal-form form.hidden').css('display', 'none');
        }

        jQuery('html, body').animate({
            scrollTop: jQuery("#anmeldeForm").offset().top
        }, 850);

        jQuery(document).on('gform_post_render', function () {

            if (isReferal === "1") {
                jQuery('.referal-form form.hidden').css('display', 'block');
                jQuery('.non-referal-form form.hidden').css('display', 'none');
            } else {
                jQuery('.non-referal-form form.hidden').css('display', 'block');
                jQuery('.referal-form form.hidden').css('display', 'none');
            }

        });

        trackEvent('course_date', 'click', 'date select');
    });
    //);
}

function showDates() {
    jQuery('.courseBlock:not(.selected)').parent('div').removeClass('hidden');
}

function hideDates() {
    jQuery('.courseBlock:not(.selected)').parent('div').addClass('hidden');
}

function dateSelected(courseID, gfID, ref) {
    jQuery('.dyn-course select').val(courseID);
    jQuery('.courseBlock').removeClass('selected');

    jQuery('.dyn-course select').val(courseID);

    if (ref === "1") {
        jQuery('.referal-form form.hidden').css('display', 'block');
        jQuery('.non-referal-form form.hidden').css('display', 'none');
    } else {
        jQuery('.non-referal-form form.hidden').css('display', 'block');
        jQuery('.referal-form form.hidden').css('display', 'none');
    }

    jQuery('html, body').animate({
        scrollTop: jQuery("#anmeldeForm").offset().top
    }, 850);

    trackEvent('course_date', 'click', 'date select');

    jQuery('.courseBlock[data-courseID=' + courseID + ']').addClass('selected');
}
