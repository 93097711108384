/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {

            },
            finalize: function () {

                function handleNavigation() {
                    if (jQuery(window).width() < 768) {
                        //jQuery('#topNav').removeClass('fixed-top').addClass('fixed-bottom');
                        //jQuery('.banner').css('margin-bottom', '0');
                    } else {
                        //jQuery('#topNav').removeClass('fixed-bottom').addClass('fixed-top');
                        //jQuery('.banner').css('margin-bottom', '79px');
                    }
                }

                jQuery('#menu-btn-burger').on('click', function (e) {
                    jQuery(this).toggleClass('open');
                });

                jQuery(window).resize(function () {
                    handleNavigation();
                });
                jQuery(document).resize(function () {
                    handleNavigation();
                });

                handleNavigation();
            }
        },
        // Home page
        'home': {
            init: function () {
                headerSlider();
            },
            finalize: function () {
                generateCalendarPopover();
                jQuery('.cal-navigate-prev').hide();
            }
        },
        'kurse': {
            init: function() {
                headerSlider();
            },
            finalize: function () {
                var $btns = $('.btn').click(function () {
                    if (this.id === 'all') {
                        $('#courses .row > .course-item').fadeIn(450);
                    } else {
                        var $el = $('.' + this.id).fadeIn(450);
                        $('#courses .row > .course-item').not($el).fadeOut();
                    }
                    $btns.removeClass('active');
                    $(this).addClass('active');
                });
            }
        },
        'team': {
            init: function () {

            },
            finalize: function () {

            }
        },
        'single_reisen': {
            finalize: function () {
                processAutoheight();

                jQuery(document).ready(function () {
                    jQuery(window).resize(function () {
                        processAutoheight();
                    });
                    jQuery(document).resize(function () {
                        processAutoheight();
                    });
                    processAutoheight();
                });

                travelGallery();

                jQuery(document).bind('gform_page_loaded', function (event, form_id, current_page) {
                    //if(current_page >= 2){
                    jQuery('form.hidden').css('display', 'block');
                    //}

                    jQuery('ul.prog li[data-index=' + current_page + ']').addClass("active");

                    jQuery('select').addClass("gfield_select");
                    jQuery('.gfield_required').html('Pflichtfeld');

                    trackEvent('form', 'progress', form_id + "/" + current_page);
                });

                jQuery('.book_trip').on('click', function (e) {
                    jQuery('.booknow-cta').addClass("active");
                    jQuery('#gform_9').removeClass("hidden");
                    doScrolling('#gform_9', 650);

                    trackEvent('cta', 'click', 'reisen buchen');
                });
            }
        },
        'single_events': {
            init: function () {

            },
            finalize: function () {
                processAutoheight();

                jQuery(document).ready(function () {
                    jQuery(window).resize(function () {
                        processAutoheight();
                    });
                    jQuery(document).resize(function () {
                        processAutoheight();
                    });
                    processAutoheight();
                });

                travelGallery();

                jQuery(document).bind('gform_page_loaded', function (event, form_id, current_page) {
                    jQuery('form.hidden').css('display', 'block');

                    jQuery('ul.prog li[data-index=' + current_page + ']').addClass("active");

                    jQuery('select').addClass("gfield_select");
                    jQuery('.gfield_required').html('Pflichtfeld');

                    trackEvent('form', 'progress', form_id + "/" + current_page);
                });

                jQuery('.book_trip').on('click', function (e) {

                    jQuery('.booknow-cta').addClass("active");
                    jQuery('#gform_5').removeClass("hidden");
                    doScrolling('#gform_5', 650);

                    trackEvent('cta', 'click', 'event buchen');
                });

               /* var waypoint = new Waypoint({
                  element: document.getElementById('bottom-in-view-example'),
                  handler: function(direction) {
                    notify('Bottom of element hit bottom of viewport')
                  },
                  offset: 'top-in-view'
                })*/
            }
        },
        'page_template_template_club': {
            init: function () {

            },
            finalize: function () {
                processAutoheight();

                jQuery(document).ready(function () {
                    jQuery(window).resize(function () {
                        processAutoheight();
                    });
                    jQuery(document).resize(function () {
                        processAutoheight();
                    });
                    processAutoheight();
                });

                jQuery(document).bind('gform_page_loaded', function (event, form_id, current_page) {

                    //console.log(event, form_id, current_page);

                    jQuery('ul.prog li').removeClass('active');

                    jQuery('ul.prog li').data('index', current_page).addClass('active');

                    if (current_page >= 2) {
                        jQuery('form.hidden').css('display', 'block');
                    } else if (current_page === 3) {
                        //console.log("card input");
                    }

                    trackEvent('form', 'progress', form_id + "/" + current_page);
                });

                travelGallery();


            }
        },
        'single_kurse': {
            init: function () {

            },
            finalize: function () {
                generatePopOverTemplate();

                jQuery(document).bind('gform_page_loaded', function (event, form_id, current_page) {

                    //console.log(event, form_id, current_page);

                    if (current_page >= 2) {
                        hideDates();
                        jQuery('form.hidden').css('display', 'block');
                    } else if (current_page === 1) {
                        showDates();
                        jQuery('form.hidden').css('display', 'block');
                    } else {
                        jQuery('form.hidden').css('display', 'block');
                    }

                    jQuery('ul.prog li[data-index=' + current_page + ']').addClass("active");


                    jQuery('select').addClass("gfield_select");
                    jQuery('.gfield_required').html('Pflichtfeld');

                    trackEvent('form', 'progress', form_id + "/" + current_page);
                });

                jQuery('.slick-slider').slick({});
            }
        }
    };


    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
