function headerSlider() {
    jQuery(".header-slider").slick({
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        pauseOnHover: false,
    });
}


function generateCalendarPopover() {

    var content = function () {

        return [jQuery(this).data('events')];
    };

    var popoverTemplate = ['<div class="calendar-details popover">',
        '<h4 class="popover-title"></h4>',
        '<div class="arrow"></div>',
        '<div class="popover-content">',
        '</div>',
        '</div>'
    ].join('');


    jQuery('[data-toggle="popover"]').popover({
        trigger: "manual",
        html: true,
        animation: false,
        template: popoverTemplate,
        content: content,
        placement: function (context, source) {
            var position = jQuery(source).position();

            if (position.left > 150) {
                return "left";
            }

            if (position.left < 515) {
                return "right";
            }

            if (position.top < 110) {
                return "bottom";
            }

            return "top";
        }
    })
        .on("mouseenter", function () {
            var _this = this;
            jQuery(this).popover("show");
            jQuery(".popover").on("mouseleave", function () {
                jQuery(_this).popover('hide');
            });
        }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!jQuery(".popover:hover").length) {
                jQuery(_this).popover("hide");
            }
        }, 150);
    });
}

function browseCalendar(mth) {
    moment.locale('de');
    var currentDate = moment(mth, "YYYYMMDD");

    if (currentDate.diff(moment(), 'days') > 1) {
        jQuery('.cal-navigate-prev').show();
        jQuery('.cal-navigate-next').hide();
    } else {
        jQuery('.cal-navigate-prev').hide();
        jQuery('.cal-navigate-next').show();
    }

    jQuery.ajax({
        url: './wp-admin/admin-ajax.php',
        data: {action: 'calendar', month: mth},
        type: 'post',
        success: function (output) {
            jQuery('.calendar').html('').append(output);
            jQuery('.cal--title').text(currentDate.format('MMMM'));
            generateCalendarPopover();
        }

    });
    return false;
}
